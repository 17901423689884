import React from "react";

import styles from "./Separator.module.scss";

interface IProps {
	className?: string;
}

const Separator = ({ className }: IProps) => {
	return (
		<hr className={`${styles.separator} ${className ?? ""}`} data-testid="Separator_wrapper" />
	);
};

export default Separator;

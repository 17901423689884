import { Fragment } from "react";

import { IBannerCflData } from "@components/ContentfulComponents/ComponentBanner/type";
import { IRichTextCflData } from "@components/ContentfulComponents/ComponentRichText/type";
import { ITextAssetAndCtasCflData } from "@components/ContentfulComponents/ComponentTextAssetAndCtas/type";
import { TMultiContentCflDataContent } from "@components/ContentfulComponents/ComposeMultiContent/type";
import RichText from "@components/ContentfulComponents/ComponentRichText/RichText.component";
import Banner from "@components/ContentfulComponents/ComponentBanner/Banner.component";
import TextAssetAndCtas from "@components/ContentfulComponents/ComponentTextAssetAndCtas/TextAssetAndCtas.component";
import ContentPagesContentBlock from "@components/ContentfulComponents/ContentPagesContentBlock/ContentPagesContentBlock.component";
import BlockCarousel from "@components/ContentfulComponents/BlockCarousel/BlockCarousel.component";
import Reference from "@components/ContentfulComponents/ComponentReference/Reference.component";
import {
	EGridSize,
	ETitleAlignment,
} from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { IBlockCarouselCflData } from "@components/ContentfulComponents/BlockCarousel/type";
import { EBlockTypenames, EComponentTypenames } from "@shared/contentful/contentful.types";
import { IReferenceCflData } from "@components/ContentfulComponents/ComponentReference/type";

import styles from "@components/ContentfulComponents/ComposeMultiContent/compose-multi-content.module.scss";

interface IMultiContent {
	items: TMultiContentCflDataContent[];
}

const MultiContent = ({ items }: IMultiContent) => {
	if (!items?.length) return null;

	return (
		<>
			{items.map((item, index) => {
				if (!item?.__typename) return null;

				switch (item.__typename) {
					case EComponentTypenames.RichText:
						return (
							<Fragment key={item.sys?.id}>
								<RichText data={item as IRichTextCflData} />
							</Fragment>
						);
					case EComponentTypenames.TextAssetCtas:
						return (
							<Fragment key={item.sys?.id}>
								<TextAssetAndCtas data={item as ITextAssetAndCtasCflData} />
							</Fragment>
						);
					case EComponentTypenames.Banner:
						return (
							<Fragment key={item.sys?.id}>
								<Banner data={item as IBannerCflData} />
							</Fragment>
						);
					case EComponentTypenames.Reference:
						return (
							<div key={`${item.sys?.id}-${index}`} className="container-grid medium-grid">
								<Reference data={item as IReferenceCflData} wrapperClassname={styles.reference} />
							</div>
						);
					case EBlockTypenames.PagesContentBlock:
						return (
							<Fragment key={item.sys?.id}>
								<ContentPagesContentBlock
									data={item}
									wrapperClassName={styles.contentPagesContentBlock}
									alignTitle={ETitleAlignment.Center}
								/>
							</Fragment>
						);
					case EBlockTypenames.Carousel:
						return (
							<Fragment key={item.sys?.id}>
								<BlockCarousel data={item as IBlockCarouselCflData} gridSize={EGridSize.Medium} />
							</Fragment>
						);

					default:
						return null;
				}
			})}
		</>
	);
};

export default MultiContent;

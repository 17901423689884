import AICarousel from "@components/ContentfulComponents/ComponentReference/ComponentAICarousel/AICarousel.component";
import Separator from "@components/ContentfulComponents/ComponentReference/ComponentSeparator/Separator.component";
import { EReferenceToComponents, IReferenceCflData } from "./type";
import { IHomeRecommendation } from "@components/ContentfulComponents/ComposeHome/type";
import { EGridSize } from "../ContentPagesContentBlock/type";

export interface IReferenceProps {
	data: IReferenceCflData;
	gridSize?: EGridSize;
	recommendation?: IHomeRecommendation;
	wrapperClassname?: string;
}

const Reference = ({ data, recommendation, gridSize, wrapperClassname = "" }: IReferenceProps) => {
	if (!data?.referenceTo) return null;

	const { referenceTo, carouselType, title } = data;

	switch (referenceTo) {
		case EReferenceToComponents.Separator:
			return <Separator className={wrapperClassname} />;
		case EReferenceToComponents.AICarousel:
			return (
				<AICarousel
					carouselType={carouselType}
					title={title}
					wrapperClassname={wrapperClassname}
					recommendation={recommendation}
					gridSize={gridSize}
				/>
			);
		default:
			return null;
	}
};

export default Reference;

import { ReactNode } from "react";
import { TRichTextEntryNode } from "@shared/contentful/contentful.types";
import styles from "./compose-text-only.module.scss";

const StaticPageGeneric = (
	_node: TRichTextEntryNode,
	children: ReactNode,
	Tag: keyof JSX.IntrinsicElements
) => {
	return <Tag className={`${styles.subtitle} ${styles.columns}`}>{children}</Tag>;
};

export const StaticPageParagraph = (_node: TRichTextEntryNode, children: ReactNode) => {
	return <p className={`${styles.columns} ${styles.paragraph}`}>{children}</p>;
};

export const StaticPageHeading2 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return StaticPageGeneric(_node, children, "h2");
};

export const StaticPageHeading3 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return StaticPageGeneric(_node, children, "h3");
};

export const StaticPageHeading4 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return StaticPageGeneric(_node, children, "h4");
};

export const StaticPageHeading5 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return StaticPageGeneric(_node, children, "h5");
};

export const StaticPageHeading6 = (_node: TRichTextEntryNode, children: ReactNode) => {
	return StaticPageGeneric(_node, children, "h6");
};

export const StaticPageUlList = (_node: TRichTextEntryNode, children: ReactNode) => {
	return <ul className={`${styles.ulSpacing} ${styles.columns}`}>{children}</ul>;
};

export const StaticPageOlList = (_node: TRichTextEntryNode, children: ReactNode) => {
	return <ol className={`${styles.olSpacing} ${styles.columns}`}>{children}</ol>;
};

export const StaticPageBlockQuote = (_node: TRichTextEntryNode, children: ReactNode) => {
	return (
		<blockquote className={`${styles.columns} ${styles.blockQuoteStyles}`}>{children}</blockquote>
	);
};

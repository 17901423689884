import React from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import { isEmpty } from "lodash";

import { IPageContent } from "@components/ContentfulComponents/ComposePage/type";
import { ITextOnlyPageCflData } from "@components/ContentfulComponents/ComposeTextOnly/type";
import {
	StaticPageBlockQuote,
	StaticPageHeading2,
	StaticPageHeading3,
	StaticPageHeading4,
	StaticPageHeading5,
	StaticPageHeading6,
	StaticPageOlList,
	StaticPageParagraph,
	StaticPageUlList,
} from "@components/ContentfulComponents/ComposeTextOnly/utils";
import { renderRichTextWithLinks } from "@shared/contentful/contentful-render.utils";
import { replaceNonBreakingSymbol } from "@shared/text/text.utils";

import styles from "@components/ContentfulComponents/ComposeTextOnly/compose-text-only.module.scss";

interface IProps {
	data: IPageContent<ITextOnlyPageCflData>;
}
//TODO: use formatFilledRichTextElement from contentful.utils instead of the functions below
const additionalRenderNodeOptions = {
	[BLOCKS.PARAGRAPH]: StaticPageParagraph,
	[BLOCKS.HEADING_2]: StaticPageHeading2,
	[BLOCKS.HEADING_3]: StaticPageHeading3,
	[BLOCKS.HEADING_4]: StaticPageHeading4,
	[BLOCKS.HEADING_5]: StaticPageHeading5,
	[BLOCKS.HEADING_6]: StaticPageHeading6,
	[BLOCKS.UL_LIST]: StaticPageUlList,
	[BLOCKS.OL_LIST]: StaticPageOlList,
	[BLOCKS.QUOTE]: StaticPageBlockQuote,
};

const ComposeTextOnly = ({ data }: IProps) => {
	if (!data || isEmpty(data)) return null;

	return (
		<div
			className={`container-grid medium-grid ${styles.gridGap}`}
			data-testid="ComposeTextOnly_wrapper"
		>
			{data.title && (
				<h1 className={styles.title} data-testid="ComposeTextOnly_title">
					{replaceNonBreakingSymbol(data.title)}
				</h1>
			)}
			{data.content?.contentCollection?.items?.map((item) => {
				if (!item?.richTextContent?.json) return null;
				return renderRichTextWithLinks({
					richTextDocument: item.richTextContent.json,
					openLinksInANewTab: item.openLinksInANewTab,
					additionalRenderNodeOptions,
				});
			})}
		</div>
	);
};

export default ComposeTextOnly;

import React from "react";
import { isEmpty } from "lodash";

import { IPageContent } from "@components/ContentfulComponents/ComposePage/type";
import { IMultiContentPageCflData } from "@components/ContentfulComponents/ComposeMultiContent/type";
import styles from "@components/ContentfulComponents/ComposeMultiContent/compose-multi-content.module.scss";
import MultiContent from "@components/ContentfulComponents/ComposeMultiContent/MultiContent/MultiContent.component";
import { replaceNonBreakingSymbol } from "@shared/text/text.utils";

interface IProps {
	data: IPageContent<IMultiContentPageCflData>;
}

const ComposeMultiContent = ({ data }: IProps) => {
	if (!data || isEmpty(data)) return null;

	return (
		<div className={styles.innerWrapper}>
			{data.title && (
				<h1 className={styles.title} data-testid="ComposeMultiContent_title">
					{replaceNonBreakingSymbol(data.title)}
				</h1>
			)}
			<MultiContent items={data.content?.contentCollection?.items} />
		</div>
	);
};

export default ComposeMultiContent;

import React, { useEffect } from "react";
import { GetServerSideProps } from "next";
import { isEmpty } from "lodash";

import Layout from "@components/Layout/Layout.component";
import JsonScriptSetter from "@components/JsonScriptSetter/JsonScriptSetter.component";
import {
	IPageCflData,
	IPageContent,
	IContentPageProps,
	IStaticPageQueryVariables,
} from "@components/ContentfulComponents/ComposePage/type";
import { contentPageQuery } from "@components/ContentfulComponents/ComposePage/slug.query";
import { IMultiContentPageCflData } from "@components/ContentfulComponents/ComposeMultiContent/type";
import { ITextOnlyPageCflData } from "@components/ContentfulComponents/ComposeTextOnly/type";
import MenuSeo from "@components/Header/Components/MenuSeo/MenuSeo.component";
import PageContent from "@components/ContentfulComponents/PageContent/PageContent.component";
import { otherLocale, defaultLocale } from "@config/languages";
import { graphQLWithPreviewRequest } from "@hooks/useGraphql";
import { useAbsolute } from "@hooks/useAbsolute";
import { normalizeRobot } from "@shared/contentful/slug.utils";
import { convertDefaultLocale } from "@shared/contentful/contentful.utils";
import { EDataLayerPageType } from "@shared/datalayer/datalayer.types";
import { deleteUrlParameter } from "@utils/url.utils";
import { logServerError, logServerInfo } from "@utils/logServer";
import { getMenuSeoLinks } from "@utils/seo.utils";

export const getServerSideProps: GetServerSideProps = async ({
	params,
	locale,
	preview = false,
}) => {
	const graphQLData = await graphQLWithPreviewRequest<
		IStaticPageQueryVariables,
		IPageCflData<IMultiContentPageCflData | ITextOnlyPageCflData>
	>({
		query: contentPageQuery,
		variables: {
			slug: params?.slug as string,
			locale: convertDefaultLocale(locale as string),
			defaultLocale,
			otherLocale,
		},
		preview,
	});

	if (graphQLData && "error" in graphQLData) {
		logServerError(
			`an error occured while building content page with slug: ${params?.slug} - ${JSON.stringify(
				graphQLData.error
			)}`
		);
		return {
			notFound: true,
		};
	}

	const data = graphQLData?.pageCollection?.items?.[0];

	if (!data) {
		logServerInfo(`no data for content page: ${params?.slug}`);
		return {
			props: {
				data: null,
				contentJson: {},
			},
		};
	}

	const { seo, content } = data;

	if (!seo) {
		logServerInfo(`content page ${params?.slug} - seo is missing`);
		return {
			props: {
				data,
				contentJson: content?.json ?? {},
			},
		};
	}

	const { slug, slugEnglish, slugFrench } = data;

	const { description = "", noFollow = false, noIndex = false, title: metaTitle = "" } = seo;

	const metas = {
		metaTitle,
		metaDescription: description,
		isMetaGeneral: true,
		isMetaPriceImage: false,
		fr: `/content/${slugFrench}`,
		en: `/content/${slugEnglish}`,
	};

	const contentJson = content?.json ?? {};

	const menuSeoLinks = await getMenuSeoLinks(locale);

	return {
		props: {
			data,
			noFollow,
			noIndex,
			metas,
			slug,
			contentJson,
			preview,
			menuSeoLinks,
		},
	};
};

const Slug = ({
	data,
	noFollow,
	noIndex,
	metas,
	slug,
	absoluteUrl,
	contentJson,
	region,
	preview,
	menuSeoLinks,
}: IContentPageProps<IPageContent<IMultiContentPageCflData | ITextOnlyPageCflData> | null>) => {
	const { absoluteUrl: notFoundUrl } = useAbsolute("404");

	useEffect(() => {
		if (!data) {
			window.location.href = notFoundUrl;
		}
	}, [notFoundUrl]);

	const metaIndex = normalizeRobot("index", noIndex);
	const metaFollow = normalizeRobot("follow", noFollow);
	const canonical = deleteUrlParameter(`${absoluteUrl}/content/${slug}`);

	const robots = `${metaIndex},${metaFollow}`;

	return (
		<Layout
			pageType={EDataLayerPageType.Content}
			robots={robots}
			metas={metas}
			openGraphTitle={metas?.metaTitle}
			currentUrl={canonical}
			additionalDataLayerInfo={contentJson}
			region={region}
			preview={preview}
			hideReinsurance={data?.content?.hideReinsuranceBlock}
		>
			{!isEmpty(contentJson) && <JsonScriptSetter json={contentJson} />}
			<MenuSeo menuSeoLinks={menuSeoLinks} absoluteUrl={absoluteUrl} />
			<PageContent data={data as IPageContent<IMultiContentPageCflData | ITextOnlyPageCflData>} />
		</Layout>
	);
};

export default Slug;

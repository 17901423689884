import CarouselProductRecommendation from "@components/Carousels/CarouselProductRecommendation/CarouselProductRecommendation.component";
import { IHomeRecommendation } from "@components/ContentfulComponents/ComposeHome/type";
import { EGridSize } from "@components/ContentfulComponents/ContentPagesContentBlock/type";
import { ERecommendationType } from "@shared/recommendation/recommendation.types";
import { formatTitleForDataLayerList } from "@components/ContentfulComponents/ComposeHome/HomeContent/HomeContent.utils";

import styles from "@components/ContentfulComponents/ComponentReference/ComponentAICarousel/AICarousel.module.scss";

export interface IAICarouselProps {
	carouselType: string | null;
	title: string | null;
	gridSize?: EGridSize;
	recommendation?: IHomeRecommendation;
	wrapperClassname?: string;
}

const AICarousel = ({
	carouselType,
	recommendation,
	title,
	gridSize = EGridSize.Large,
	wrapperClassname = "",
}: IAICarouselProps) => {
	if (!carouselType || !recommendation || carouselType !== ERecommendationType.MostPopular)
		return null;

	const titleTranslation = title ?? "";

	return (
		<CarouselProductRecommendation
			dataLayerListName={formatTitleForDataLayerList(title, true)}
			crossSellingData={recommendation.mostPopularProducts}
			gridSize={gridSize}
			recommendationType={ERecommendationType.MostPopular}
			titleTranslation={titleTranslation}
			wrapperClassname={wrapperClassname}
			layoutWrapperClassname={styles.layoutWrapper}
		/>
	);
};

export default AICarousel;

import { EPageTypenames, IPageContent } from "@components/ContentfulComponents/ComposePage/type";
import { IMultiContentPageCflData } from "@components/ContentfulComponents/ComposeMultiContent/type";
import { ITextOnlyPageCflData } from "@components/ContentfulComponents/ComposeTextOnly/type";
import ComposeMultiContent from "@components/ContentfulComponents/ComposeMultiContent/ComposeMultiContent.component";
import ComposeTextOnly from "@components/ContentfulComponents/ComposeTextOnly/ComposeTextOnly.component";

interface IComponentPageContent {
	data: IPageContent<IMultiContentPageCflData | ITextOnlyPageCflData>;
}

const PageContent = ({ data }: IComponentPageContent) => {
	if (!data) return null;

	switch (data.content?.__typename) {
		case EPageTypenames.MultiContent:
			return <ComposeMultiContent data={data as IPageContent<IMultiContentPageCflData>} />;
		case EPageTypenames.Text:
			return <ComposeTextOnly data={data as IPageContent<ITextOnlyPageCflData>} />;

		default:
			return null;
	}
};

export default PageContent;

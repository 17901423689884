import { isEmpty } from "lodash";
import React from "react";
import Asset from "../ComponentAsset/Asset.component";
import styles from "./banner.module.scss";
import { IBannerCflData } from "./type";

interface IProps {
	data: IBannerCflData;
}

const Banner = ({ data }: IProps) => {
	if (!data || isEmpty(data)) return null;
	const { bannerTitle, bannerType, asset } = data;
	if (!asset) return null;
	const isTypePrimary = bannerType === "primary";
	const bannerStyle = isTypePrimary ? styles.bannerPrimary : styles.bannerSecondary;
	return (
		<div className="container-grid medium-grid" data-testid="Banner_wrapper">
			{bannerTitle && (
				<h2 className={styles.bannerTitle} data-testid="Banner_title">
					{bannerTitle}
				</h2>
			)}
			<div className={`${styles.assetWrapper} ${bannerStyle}`} data-testid="Banner_asset-wrapper">
				<Asset
					className={styles.asset}
					data={asset}
					loading={!isTypePrimary}
					priority={isTypePrimary}
				/>
			</div>
		</div>
	);
};

export default Banner;
